<template>
  <Layout>
    <custom-data-table
      :fields="fields"
      :filters="filters"
      :per-page="25"
      :query="query"
      :start-page="1"
      query-node="getPaymentsLocks"
    >
      <span slot="driverId" slot-scope="props">
        <b-link :href="`/drivers/profile/${props.rowData.driverId}`" target="_blank" :title="$t('payments.labels.goToDriver')">
          {{ props.rowData.driverId }}
        </b-link>
      </span>
      <span slot="createdAt" slot-scope="props">{{ props.rowData.createdAt | moment('DD/MM/YYYY HH:mm') }}</span>
      <span slot="reason" slot-scope="props">
        <lock-reasons-enum :reason="props.rowData.reason || ''" />
      </span>
    </custom-data-table>
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
import PAYMENT_LOCKS from '@graphql/payment/getPaymentLocks.gql'
import LockReasonsEnum from '@views/fleet/common/lock-reasons-enums';
import CustomDataTable from '@components/CustomDataTable';
import { currentLanguage } from '@utils/language';
import { i18next } from '@src/translate/i18n';

export default {
  name: 'PaymentLocks',
  page: {
    title: i18next.t('safety.titles.payment_locks'),
  },
  components: {
    Layout,
    CustomDataTable,
    LockReasonsEnum
  },
  data: () => ({
    query: PAYMENT_LOCKS,
    fields: [
        {
          name: 'driverId',
          title: 'Motorista'
        },
        {
          name: 'vin',
          title: 'VIN'
        },
        {
          name: 'requestedBy',
          title: 'Bloqueado por'
        },
        {
          name: 'reason',
          title: 'Motivo de Bloqueio'
        },
        {
          name: 'createdAt',
          title: 'Data de criação'
        },
    ],
  }),
  computed: {
    userLanguage() {
      return currentLanguage;
    },
    filters() {
      return {
        driverId: {
          type: 'textfield',
          props: {
            label: 'Driver id',
            placeholder: 'Busque por motorista',
          },
          graphqlQuery(value) {
            return {
              driverId: value,
            };
          },
        },
        vin: {
          type: 'textfield',
          props: {
            label: 'VIN',
            placeholder: 'Busque por vin',
          },
          graphqlQuery(value) {
            return {
              vin: value,
            };
          },
        },
        startDate: {
          type: 'datepicker',
          props: {
            label: 'A partir de',
            range: false,
            format: 'DD/MM/YYYY',
            confirm: false,
            width: 'auto',
            language: this.userLanguage
          },
          valueParserType: String,
          valueParserFunction: date => {
            return this.$moment(date);
          },
          graphqlQuery(date) {
            if (date.isValid())
            return {
              startDate: date.format('YYYY-MM-DD')
            };
          }
        },
        endDate: {
          type: 'datepicker',
          props: {
            label: 'Até dia',
            range: false,
            format: 'DD/MM/YYYY',
            confirm: false,
            width: 'auto',
            language: this.userLanguage
          },
          valueParserType: String,
          valueParserFunction: date => {
            return this.$moment(date);
          },
          graphqlQuery(date) {
            if (date.isValid())
            return {
              endDate: date.format('YYYY-MM-DD')
            };
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.not_found {
  padding: 140px 0px !important;
  text-align: center;
  color: #c0c0c0;
  font-size: 30px;
}
</style>