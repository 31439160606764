var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("custom-data-table", {
        attrs: {
          fields: _vm.fields,
          filters: _vm.filters,
          "per-page": 25,
          query: _vm.query,
          "start-page": 1,
          "query-node": "getPaymentsLocks",
        },
        scopedSlots: _vm._u([
          {
            key: "driverId",
            fn: function (props) {
              return _c(
                "span",
                {},
                [
                  _c(
                    "b-link",
                    {
                      attrs: {
                        href: `/drivers/profile/${props.rowData.driverId}`,
                        target: "_blank",
                        title: _vm.$t("payments.labels.goToDriver"),
                      },
                    },
                    [_vm._v(" " + _vm._s(props.rowData.driverId) + " ")]
                  ),
                ],
                1
              )
            },
          },
          {
            key: "createdAt",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(
                    _vm._f("moment")(
                      props.rowData.createdAt,
                      "DD/MM/YYYY HH:mm"
                    )
                  )
                ),
              ])
            },
          },
          {
            key: "reason",
            fn: function (props) {
              return _c(
                "span",
                {},
                [
                  _c("lock-reasons-enum", {
                    attrs: { reason: props.rowData.reason || "" },
                  }),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }